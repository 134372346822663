export default {
    "title": "title",
    "home": "Home",
    "Reviews": "Reviews",
    "Brokers": "Brokers",
    "FinTech": "FinTech",
    "LP": "Liquidity",
    "Pay": "Payment",
    "TopBrokers": "Top Brokers",
    "Promotion": "Promotions",
    "Vlog": "Trader TV",
    "Blog": "Blog",
    "Creativecenter": "Creative Center",
    "Top List": "Ranking List",
    "Top Trader": "Top Trader",
    "Verify Account": "Verify Account",
    "Notice": "Notice",
    "System Notifications": "System Notifications",
    

    "language": "Language",
    "Sign in" : "Sign in",
    "Sign up" : "Sign up",
    "Log Out" : "Log Out",
    "Modify Password" : "Modify Password",
    "Request timeout": "Request timeout",
    "Network error": "Network error",
    "videoerror": "This video is temporarily unavailable, please try again later.",
    index: {
      "banner": "Honest Reviews，fair rankings",
      "searchcon": "search for a company",
      "search": "Search",
      "cont1" : "Popular Brands",
      "cont2" : "Excellent platform to help you seize Market opportunities",
      "cont3" : "Recent Reviews",
    },
    footer:{
      "work": "Work With Us",
      "work1" : "Tradervote is a Review platform that's open  to everyone. Share your experiences to help others make better choices.",
      "login": "Log in",
      "cont1": "subscribe to our newsletter",
      "cont2": "please enter your Email",
      "cont3": "Subscribe",
      "cont4": "register now to submit your Reviews",
      "cont5" : "about Tradervote",
      "cont6" : "about us",
      "cont7" : "community",
      "cont8" : "help center",
      "cont9" : "app",
      "cont10" : "follow us on",
      "cont11" : "Privacy policy",
      "cont12" : "User service agreement",
      "cont13" : "Cookie agreement",
    },
    login: {
      'cont1': 'Please read and agree to the agreement.',
      'cont2': 'Please enter a mailbox or user name.',
      'cont3': 'Please enter the password.',
      'cont4': 'Password length is 8-12 digits.',
      'cont5': 'Please enter a user name.',
      'cont6': 'Starting with a letter, the length of letters, numbers and underscores is 2 to 15.',
      'cont7': 'Please enter the captcha.',
      'cont8': 'Please enter a mailbox.',
      'cont9': 'The mailbox format is incorrect.',
      "cont10" : "Email address",
      "cont11": "User Name",
      "cont12": "Password",
      "cont13": "Captcha",
      "cont14": "I agree to the ",
      "cont15" : "terms",
      "cont16" : "and",
      "cont17" : "privacy policy",
      "cont18" : "Already have an Account? ",
      "cont19" : "LOGIN WITH",
      "cont20" : "Send",
      "cont21": " Send in seconds",
      "cont22": "Sending",
      "cont23": "Individual",
      "cont24": "Company",
      "cont25" : "New Buddy!",
      "cont26" : "Welcome back!",
      "cont27" : "Email Address or User Name",
      "cont28" : "Remember password",
      "cont29" : "Don’t have an account? ",
      "cont30" : "Retrieve Password?",
      "cont31": "Retrieve Password",
      "cont32": "New password",
      "cont33": "Confirm password",
      "cont34": "Uploading avatar pictures can only be in JPEG/JPG/PNG format!",
      "cont35" : "The size of the uploaded avatar image cannot exceed 2MB!",
      "cont36" : "Please input the password again.",
      "cont37" : "Two inputs don't match!",
      "cont38" : "Link copied",
      "cont39" : "Please enter the old password.",
      "cont40" : "Length must be greater than 8 digits.",
      "cont41": "Length must be less than 12 digits.",
      "cont42": "Please enter the new password.",
      "cont43": "Please enter the confirm password.",
      "cont44": "Please bind your email first",
      "cont45" : "Please enter the content",
      "cont46" : "Login with facebook",
      "cont47" : "User",
      "cont48" : "",
      "cont49" : "",
      "cont50" : "",
    },
    user:{
      "cont1": "Personal settings",
      "cont2": "Upload a new profile picture",
      "cont3": "Bind Email",
      "cont4": "Your profile picture:",
      "cont5" : "Email:",
      "cont6" : "Confirm binding",
      "cont7" : "User name(public visible):",
      "cont8" : "Country:",
      "cont9" : "Personal profile:",
      "cont10" : "Mandatory",
      "cont11": "Please username",
      "cont12": "Please select your country",
      "cont13": "Please input Activity name",
      "cont14": "Length should be 3 to 12",
      "cont15" : "Please enter a mailbox or user name.",
      "cont16" : "The mailbox format is incorrect.",
      "cont17" : "Modify Password",
      "cont18" : "Old password",
      "cont19" : "New password",
      "cont20" : "Confirm",
      "cont21": "Email",
      "cont22": "Go to bind email",
      "cont23": "",
      "cont24": "",
      "cont25" : "",
      "cont26" : "",
      "cont27" : "",
      "cont28" : "",
      "cont29" : "",
      "cont30" : "",
      "cont31": "",
      "cont32": "",
      "cont33": "",
      "cont34": "",
      "cont35" : "",
      "cont36" : "",
      "cont37" : "",
      "cont38" : "",
      "cont39" : "",
      "cont40" : "",
    },
    detail: {
      "about": "About ",
      "service": "Product service",
      "assets": "Tradable assets",
      "platforms": "Trading platforms",
      "leverage": "Maximum leverage",
      "licenses": "Licenses",
      "method": "Payment method",
      "contact": "Contact",
      "support": "Support currencies",
      "cont1": "Visit this website",
      "cont2": "Rate this Place",
      "cont3": "Rataings and Reviews",
      "cont4": "total",
      "cont5" : "Star",
      "cont6" : "Reset",
      "cont7" : "Length should be between 15 and 1000",
      "cont8" : "Top Rated",
      "cont9" : "Your review is pending.",
      "cont10" : "reviews",
      "cont11": "Date of review",
      "cont12": "Review of",
      "cont13": "Edit",
      "cont14": "Delete",
      "cont15" : "Honest Reviews,fair rankings",
      "cont16" : "Learn more",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
    },
    about:{
      "cont1": "About Tradervote",
      "cont2": "Tradervote is a professional review company dedicated to improving transparency and reliability in the forex trading market. Our team consists of experienced forex traders, analysts, and technical experts. Our mission is to regulate and constrain broker behavior through genuine and objective reviews, providing safer and more effective services for investors.",
      "cont3": "Mission and Goals",
      "cont4": "Mission: Our mission is to enhance transparency in the forex trading market.",
      "cont5" : "Feedback from real customers",
      "cont6" : "Tradervote like a beacon, illumines the way forward for us. This website brings together authoritative experts in the financial field, who use their professional knowledge and rich experience to provide us with the most authentic and objective financial product reviews.",
      "cont7" : "Tradervote is very powerful. Here, I can exchange ideas with other investors, share experiences and grow together. We learn from each other, encourage each other, and pursue the dream of wealth together.",
      "cont8" : "I am very satisfied with the service of this website, Tradervote provides objective and truthful reviews. Through it, I can better assess investment risks and make more rational decisions.",
      "cont9" : "I think Tradervote is a very practical financial tool, and its industry ranking allows me to find some investment opportunities suitable for myself. Recommend to everyone!",
      "cont10" : "I have been looking for a reliable financial review site for a long time and Tradervote has met my needs perfectly.",
      "cont11": "Goals:",
      "cont12": "Provide honest reviews of brokers to help investors make informed decisions",
      "cont13": "Monitor broker behavior to prevent malicious false reviews and extortion.",
      "cont14": "Team Members:",
      "cont15" : "We strive to become a trusted forex trading information platform, promoting a healthier market environment.",
      "cont16" : "Services Offered",
      "cont17" : "Broker Reviews: We objectively evaluate major forex brokers, considering trading conditions, fund security, customer support, and more.",
      "cont18" : "Authentic Feedback: We encourage investors to share genuine trading experiences, aiding others in making wise choices.",
      "cont19" : "Investor Education: We provide educational resources related to forex trading to enhance investor skills.",
      "cont20" : "Future Outlook:",
    },
    topchat:{
      "cont1": "A secure and intelligent software for secure, decentralised communication",
      "cont2": "Chat with friends, communities and co-workers",
      "cont3": "TraderChat Android",
      "cont4": "An AI assistant to help you with your daily work",
      "cont5" : "Messages",
      "cont6" : "Organize teams and discussions with customizable rooms.",
      "cont7" : "Calls",
      "cont8" : "Voice and video conferencing for 1:1s and groups.",
      "cont9" : "ChatGPT 4.0",
      "cont10" : "Search, compose emails, video scripts, translation.",
      "cont11": "What the customer says",
      "cont12": "\"I've been looking for a secure and convenient instant messaging app, and TraderChat completely meets my expectations! I love its clean and intuitive interface, and the voice and video call quality is excellent. Finally, I can stay in touch with family and friends anytime, anywhere!\" ",
      "cont13": '"As a business professional, I often need to communicate with clients and colleagues instantly. TraderChat provides a secure communication environment and smart assistant features, helping me manage work tasks more efficiently. Thanks to this excellent app!"',
      "cont14": '"I value privacy protection very much, so I chose TraderChat as my primary communication tool. Its encryption technology ensures the security of my personal information, allowing me to share life\'s moments with friends confidently. Highly recommended for users who value privacy!"',
      "cont15" : '"TraderChat\'s multi-platform support is fantastic! I can use it on my phone, tablet, and computer, and message synchronization is fast and stable. Now I can stay in touch with my family and friends anytime, anywhere. It\'s so convenient!"',
      "cont16" : "Are you ready to download the app immediately？",
      "cont17" : "TraderChat Web",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
    },
    vlog:{
      "cont1": "+Follow",
      "cont2": "Followed",
      "cont3": "Published on",
      "cont4": "Like",
      "cont5" : "Comment",
      "cont6" : "Collect",
      "cont7" : "Forward",
      "cont8" : "Share",
      "cont9" : "Personal Profile",
      "cont10" : "fan",
      "cont11": "Received ",
      "cont12": "likes",
      "cont13": "collect",
      "cont14": "About Tradervote",
      "cont15" : "Frequently asked questions",
      "cont16" : "Contact Us",
      "cont17" : "Creative Center",
      "cont18" : "Publish your creation",
      "cont19" : "Follow",
      "cont20" : "creation",
      "cont21": "Cover:",
      "cont22": "video",
      "cont23": "graphics",
      "cont24": "Title",
      "cont25" : "Content",
      "cont26" : "Type",
      "cont27" : "Click to upload",
      "cont28" : "video/mp4 files with a size less than 10M.",
      "cont29" : "Please enter a title",
      "cont30" : "Please enter a description",
      "cont31": "Please upload a cover",
      "cont32": "Please upload pictures and text",
      "cont34": "time",
      "cont35" : "praise",
      "cont36" : "article",
      "cont37" : "Category",
      "cont38" : "Recently Updated",
      "cont39" : "Share",
      "cont40" : "",
    },
    service:{
      "cont1": "Before using Tradervote services, you should carefully read the content of this Tradervote User Service Agreement, confirm that this Tradervote User Service Agreement has been known, understood and accepted, and have agreed to use it as the basis for determining the rights and obligations of both parties.",
      "cont2": 'The parties to these Terms are TOP NEW FUTURE TECHNOLOGY COMPANY LIMITED (" Tradervote "or" We ") and the user of the services related to Tradervote (" User "or" You "), These Terms are the terms of Service between you and Tradervote with respect to your use of the services provided by Tradervote and have the effect of a formal written contract.',
      "cont3": "You understand and agree that we will revise and update these Terms or the Service Rules from time to time as agreed in the Tradervote User Service Agreement. The release and implementation of the updated content are subject to the relevant provisions of the Tradervote User Service Agreement.",
      "cont4": "1. User account",
      "cont5" : "1.1 When registering, managing and using the account, you should follow the principle of good faith, legality and good faith. The relevant registration materials you submit to the platform shall comply with laws and regulations, the legitimate rights and interests of citizens, public order and good customs, and the principles of information authenticity, and shall not submit any illegal or undesirable information. If the relevant information changes, you should update it in time. If the registration information provided by you is illegal, untrue, inaccurate or not updated in time, resulting in relevant legal responsibilities or adverse consequences, you will bear the corresponding legal responsibilities and adverse consequences. At the same time, Tradervote reserves the right to refuse to provide you with registration services.",
      "cont6" : "1.1 When registering, managing and using the account, you should follow the principle of good faith, legality and good faith. The relevant registration materials you submit to the platform shall comply with laws and regulations, the legitimate rights and interests of citizens, public order and good customs, and the principles of information authenticity, and shall not submit any illegal or undesirable information. If the relevant information changes, you should update it in time. If the registration information provided by you is illegal, untrue, inaccurate or not updated in time, resulting in relevant legal responsibilities or adverse consequences, you will bear the corresponding legal responsibilities and adverse consequences. At the same time, Tradervote reserves the right to refuse to provide you with registration services.",
      "cont7" : "1.2.1 Your account nickname, profile picture, personal introduction and other personal information shall not appear illegal and bad information, including but not limited to the use of political, pornographic, vulgar, insulting, libel and other illegal and moral words.",
      "cont8" : "1.2.2 You may not use another person's name (including but not limited to using another person's name, name, font, avatar, identity, etc., or in any other manner that may cause confusion) to open an Tradervote review account without their permission.",
      "cont9" : "1.2.3 It is not allowed to create an Tradervote account by impersonating, copying, or fabricating the names and identification information of organizations such as news media or in any other manner that may cause confusion.",
      "cont10" : "1.2.4 You shall not maliciously register Tradervote accounts (including but not limited to frequent registration, batch registration and other behaviors) or use Tradervote accounts for illegal or improper purposes (including but not limited to traffic fraud and other behaviors), shall not carry out any acts that harm the legitimate rights and interests of other citizens, and shall not take various technical means to maliciously circumvent or oppose the rules of the platform.",
      "cont11": "1.3 You understand and agree that the ownership and related rights and interests of your Tradervote account are all owned by Tradervote, and you only have the right to use the account and only your own use. In order to ensure the security of your account, you shall not grant, transfer, sell, lend or otherwise license the Tradervote account to others, otherwise you shall bear all the responsibilities arising therefrom. Tradervote reserves the right to refuse to provide the relevant services, freeze or withdraw the registered account or terminate this Service Agreement. You may also be required to indemnify Tradervote for losses sustained by you.",
      "cont12": "1.4 You shall properly keep account information, account password and other information and materials related to the account. You are responsible for maintaining the security and confidentiality of your personal account and password, and assume all legal responsibilities for the activities you engage in in the name of your registered account, including but not limited to all legal liabilities that may be caused by any information Posting, browsing and clicking you perform on the Tradervote platform. You should immediately notify Tradervote of any unauthorized use of your account or any other security breach.",
      "cont13": "1.5 You understand and agree that if you violate the above terms, Tradervote has the right to take measures against you such as requiring correction within a time limit, prohibiting registration, deleting or blocking illegal information, prohibiting the release of information within different time limits and modifying, blocking and cancelling account information.",
      "cont14": "1.6 You understand and agree that you may use the services and functions of Tradervote by registering a new account or logging in to your existing account, and your use of Tradervote account shall comply with the provisions of the Tradervote User Service Agreement.",
      "cont15" : "2. User management",
      "cont16" : "2.1 You acknowledge, understand and agree that the collection, storage, use, sharing and protection of users' personal information may be involved in the course of Tradervote's services. When you use the services provided by Tradervote, you agree that Tradervote collects, uses and shares personal information in accordance with the Tradervote Privacy Policy.",
      "cont17" : "2.2 Users have the right to post objective, real and personally experienced text reviews, picture reviews, video reviews and other information on the Tradervote platform. Users have the right to participate in the Tradervote community and post articles and opinions that comply with national laws and comply with the rules of the Tradervote community.",
      "cont18" : "2.3 You may receive and view events or information through official page announcements and/or in-site letters and/or emails and/or customer service calls and/or mobile phone messages, regular correspondence.",
      "cont19" : "2.4 Users have the right to receive rewards (such as contribution value, points, badges, etc.) from Dianping after Posting review information and other contributions according to relevant regulations of Tradervote.",
      "cont20" : "2.5 When users post information, you should ensure the authenticity, objectivity and legality of such information, Tradervote encourages users to contribute high-quality experience reviews. Users should ensure that any review information, guides or articles, pictures and videos posted on Tradervote do not infringe on the privacy, copyright or other legitimate rights of third parties. The user must maintain the objectivity of the review and shall not use the Tradervote user identity to engage in any behavior that violates laws, regulations, national policies and principles of good faith, including but not limited to:",
      "cont30" : "2.5.1 Violations of laws, regulations and national policies:",
      "cont31": "· Opposed to the fundamental principles set forth in the Constitution;",
      "cont32": "· Endangering national security, disclosing state secrets or subverting state power;",
      "cont33": "· Harm national honor and interests;",
      "cont34": "· Advocating terrorism or extremism or inciting the implementation of terrorist or extremist activities;",
      "cont35" : "· Spreading rumors to disrupt economic and social order;",
      "cont36" : "· Disseminating obscenity, pornography, gambling, violence, murder, terror or instigation of crime;",
      "cont37" : "· Insulting or defaming others, infringing upon the reputation, privacy and other legitimate rights and interests of others;",
      "cont38" : "· Other contents prohibited by laws and administrative regulations.",
      "cont39" : "2.5.2 Violation of the principle of good faith:",
      "cont40" : "· Hype and charge fees or gain benefits from merchants;",
      "cont41": "· Participate in or organize the writing and Posting of false reviews for the purpose of obtaining a profit or advantage;",
      "cont42": "· Threatening bad reviews to demand additional benefits or benefits from the merchant;",
      "cont43": "· Malicious slander of Tradervote or the goodwill of merchants by fabricating facts, insulting, defaming, etc.;",
      "cont44": "· Conduct other violations that affect the objectivity of reviews and disrupt the normal order of Tradervote.",
      "cont45" : "If you have committed any of the above actions, Tradervote reserves the right to take the following actions:",
      "cont46" : "① The violation information will be screened, and the warning will be issued according to the seriousness of the case;",
      "cont47" : "② Remove all reviews and pictures, cancel review stars and community ratings, and block tribal posts or tribes that involve commercial speculation.",
      "cont48" : "③ Temporarily/permanently restrict your use of your account to post information on the Tradervote platform;",
      "cont49" : "④ Other methods prescribed by laws, administrative regulations, these Terms and the Tradervote Platform User Service Agreement.",
      "cont50" : "2.6 Users are prohibited from using Tradervote in any form as a venue, platform or medium for any illegal activity. Without the authorization or permission of Tradervote, users may not use Tradervote's name for any commercial activities, and may not use Tradervote as a venue, platform or medium for commercial activities in any form.",
      "cont51": '2.7 Tradervote requires all search engines to follow the industry norms, namely "reject Robots access standard" (RobotsExclusionStandard), otherwise it will be regarded as your crawling behavior is a violation of our Tradervote property rights and intellectual property rights, the right to protect the interests of Tradervote through legal proceedings.',
      "cont52": "2.8 If you violate these Terms, the Tradervote User Service Agreement or any service rules posted by Tradervote from time to time, Tradervote reserves the right to take all necessary measures to the extent permitted by law, including but not limited to deleting the content posted by the user, canceling the stars, honors and virtual wealth obtained by the user on Tradervote. If you suspend or terminate your use of Tradervote account and cause losses to Tradervote, you shall be liable for all damages, including but not limited to damages for property damage, damages for reputation, legal costs, attorney's fees, notary fees, transportation expenses and other reasonable expenses arising out of rights protection. Tradervote reserves the right to deal with your conduct in accordance with these Terms and the relevant provisions of the Tradervote User Service Agreement.",
      "cont53": "3. Termination of service",
      "cont54": "3.1 You agree that Tradervote reserves the right to modify or discontinue any of its free Services provided to you at any time without prior notice to you. In respect of your paid transactions with Tradervote, you agree that Tradervote has the right to modify, discontinue, and deal with subsequent matters in accordance with the principles of fairness, good faith, and equal value for money at prior notice.",
      "cont55" : "3.2 If your account meets the following conditions, Tradervote has the right to terminate your use of Tradervote. As a result, your account will no longer be able to log in to Tradervote, and the corresponding service will be terminated:",
      "cont56" : "3.2.1 There is no active business that has not expired;",
      "cont57" : "3.2.2 Termination of your account and services does not violate the mandatory provisions of relevant laws and regulations.",
      "cont58" : "3.3 If you have any objection to these Terms and any amendments from time to time, you have the right to stop using Tradervote's services, or notify Tradervote through customer service and other channels to stop providing services to you. After discontinuation of the service, unless otherwise expressly provided by laws and regulations, Tradervote has the right (but not the obligation) to retain your account to access Tradervote's relevant information and data, or to retain or forward any website messages or short messages within any account.",
      "cont59" : "3.4 You agree that after the termination of your contractual relationship with Tradervote, Tradervote may still claim rights against you in accordance with these Terms in respect of your illegal conduct or violation of these Terms and/or other Service rules during the use of Tradervote Services.",
      "cont60" : "4. Intellectual property and other rights",
      "cont61": "4.1 The operation system of Tradervote platform and related services is independently developed, operated and provided with technical support by Tradervote, and Tradervote has full rights to all data and information generated during the development and operation of Tradervote services. Tradervote owns the copyright and patent rights of the software used by Tradervote to provide various services, as well as the trademarks, business images, business logos and know-how used.",
      "cont62": "4.2 You understand and agree that the intellectual property rights and ownership of all comments, articles, videos, feedback and other information published by you on Tradervote and its derivatives shall be governed by the relevant provisions in the Tradervote Platform User Service Agreement.",
      "cont63": "5. Exoneration",
      "cont64": "5.1 Unless expressly agreed by Tradervote in writing, Tradervote does not guarantee the accuracy, completeness or reliability of any content published by merchants, including but not limited to Merchant information, review content, etc. obtained by users from Tradervote in any way (including but not limited to inclusion, via, connection or download). Users should review and judge the relevant information at their own discretion and assume their own responsibility and risk for any products, services, information or materials purchased or obtained by users as a result of the content information on Tradervote. If the user is damaged as a result, Tradervote shall be exempt from liability to the maximum extent permitted by law. All user reviews, merchant or product information posted by Tradervote users only represent the user's personal views and do not mean that Tradervote endorsements their views or confirms their descriptions. Tradervote is exempt from liability to the maximum extent permitted by law.",
      "cont65" : "5.2 If you upload or publish any information or content on the Tradervote platform, you should keep your own backup. Tradervote is not responsible for the failure to save, modify, delete or store information posted by users, and is not responsible for typographical errors, omissions, etc. on Tradervote that are not intentionally caused by Tradervote. Tradervote has the right but no obligation to improve or correct any omissions or errors in any part of Tradervote.",
      "cont66" : "5.3 You acknowledge that the rankings published by Tradervote on its platform are solely generated based on real user evaluations, visits, browsing time and other data without manual intervention. These lists are only for your selection of services to provide a reference, do not involve any merchant paid advertising, you should use your own judgment on the properties of the listed services, Tradervote will not make any form of guarantee or warranty for the services listed in this list.",
      "cont67" : "5.4 Tradervote does not assume legal responsibility for any activities or information that is not obtained through Tradervote's formal channels.",
      "cont68" : "5.5 Tradervote reserves the right, to the extent permitted by law, without notice to you, to remove any information posted by users that does not comply with these Terms and Service rules, the Tradervote Platform User Service Agreement, and legal requirements. Tradervote will not be liable for any inconvenience or loss caused to you by the deletion of such information.",
      "cont69" : "5.6  You agree that, to the extent permitted by law, Tradervote shall in no event be liable for direct, indirect, incidental, special, punitive or other damages or losses of any person or entity, including but not limited to:",
      "cont70" : "5.6.1 You understand and agree that Tradervote is not responsible for any delays or errors in the information displayed by Tradervote due to the special nature of the Internet;",
      "cont71": "5.6.2 In the course of your use of Tradervote Services, Tradervote shall not be liable for any insult, defamation, omission, obscenity, pornography or profanity caused to you by a third party;",
      "cont72": "5.6.3 Other force majeure and exclusions specified in the Tradervote User Service Agreement.",
      "cont73": "6. Dispute resolution",
      "cont74": "6.1 In the event of any dispute between you and Tradervote arising from the content or implementation of these Terms, both parties shall try their best to resolve it amicably through negotiation. If no agreement can be reached through negotiation, either party may file a lawsuit with a court of competent jurisdiction.",
      "cont75" : "6.2 All disputes, claims or other matters arising from or in connection with your use of Tradervote Services shall be governed by the laws of Vietnam, excluding the application of all conflict of laws provisions.",
      "cont76" : "7. Comments and feedback",
      "cont77" : "If the user has any requirements to explain the terms, please visit [Contact us] call instructions.",
    },
    privacy:{
      "cont1": 'TOP NEW FUTURE TECHNOLOGY COMPANY LIMITED(" We ") attaches great importance to the privacy of users (" You "). This policy applies to the products/services you currently use. When you use our products/services, we may collect and use information about you. Through this Privacy Policy, we want to explain to you how we collect, use, store and share this information when using our products/services, and the ways we provide you with access, update, control and protection of this information. This Privacy Policy is closely related to the products/services you use, we hope you read it carefully, and when necessary, make the appropriate choices according to the guidance of this Privacy Policy. For the relevant technical terms involved in this Privacy Policy, we try to express them in a concise and concise manner, and provide links to further instructions for your understanding. ',
      "cont2": "Special note: Before using the products/services provided by us, please carefully read and fully understand this Privacy Policy (the key contents of which we have made the font bold for your special attention) and make corresponding choices. By using or continuing to use our products/services, you consent to the processing of your information in accordance with this Privacy Policy. If the user has no civil capacity (minors under the age of 14), it needs to be accompanied by a guardian. Guardians are required to properly perform their guardianship duties and fully read the following agreement to ensure that you are fully aware of this policy before your child uses this product.",
      "cont3": "1. How do we collect and use your information",
      "cont4": "We will collect and use your personal information in accordance with the principles of legality, necessity and transparency for the purposes described in this policy. In this regard, we will separately explain to you the purpose, scope and use of the corresponding information by updating this policy, pop-up window, page prompts, etc., and provide you with the means of your own choice and consent, and collect and use after obtaining your express consent. If we use your personal information for other purposes not specified in this policy, or collect your personal information for other specific purposes, we will inform you in a reasonable manner and obtain your consent again before using.",
      "cont5" : "1.1 Information about the use of our products and services",
      "cont6" : "1.1.1 Read and write external stored information: When you are in use, we may need to store the data generated by you during the use of the product so that you can use it offline and save download traffic.",
      "cont7" : "1.1.2 Feedback, complaints and reporting information: When you give feedback or complain in the APP, we will collect the email information you proactively provide for the App platform customer service to contact you in time and accurately solve the problems you encounter in the process of using the App.",
      "cont8" : "1.2 How we collect your Information (1) When we need to use the above relevant permissions, we will apply to you for the necessary permissions, and after obtaining your authorization, the software will use the relevant permissions. (2) When the current application is running in the background, the application itself may still access your device status, external storage and other information for reading and loading product application data.",
      "cont9" : "1.3 How we may Use Information We may use the information we collect in the course of providing services to you for the following purposes: (1) To provide services to you; (2) for identity verification, customer service, security prevention, fraud detection, archiving and backup purposes when we provide services to ensure the security of the products and services we provide to you; (3) to help us design new services and improve our existing services; (4) To provide you with ads that are more relevant to you as an alternative to the ads that are commonly served.",
      "cont10" : "2. How do we store, share, transfer and publicly disclose your personal information",
      "cont11": "2.1 Store",
      "cont12": "2.1.1 Storage Location: We store your personal information collected and generated in the course of our operations in Vietnam in accordance with laws and regulations. At present, we do not transmit the above information abroad, and if we do, we will comply with the laws and regulations of Vietnam and foreign countries and seek your consent. ",
      "cont13": "2.1.2 Storage period: We only retain your personal information for the period necessary for the purpose of providing the current products and services. After the necessary period, we will delete or anonymize your personal information, except as otherwise provided by laws and regulations.",
      "cont14": "2.2 Share",
      "cont15" : "We do not share your personal information with any company, organization or individual, except in the following cases:",
      "cont16" : "2.2.1 Sharing with express consent: With your express consent, we will share your personal information with other parties.",
      "cont17" : "2.2.2 We may share your personal information in accordance with laws and regulations or mandatory requirements of competent government departments.",
      "cont18" : "2.2.3 Sharing with our Affiliates: Your personal information may be shared with our affiliates. We will only share necessary personal information, subject to the purposes stated in the User Privacy Policy. If the affiliated company wants to change the purpose of processing personal information, it will seek your authorized consent again. Our affiliates include any company or entity that is or will be controlled, controlled or under common control with us and the legal heirs of such company or entity. \"Control\" means having the ability, directly or indirectly, to influence the management of the company, whether through ownership, voting shares, contracts or other means recognized by the people's Court.",
      "cont19" : "2.3 Transfer",
      "cont20" : "We will not transfer your personal information to any company, organization or individual, except in the following circumstances：",
      "cont30" : "2.3.1 Transfer with express consent: After obtaining your express consent, we will transfer your personal information to other parties;",
      "cont31": "2.3.2 In the case of merger, acquisition or bankruptcy liquidation, such as the transfer of personal information, we will require the new company or organization that holds your personal information to continue to be bound by this user privacy policy, otherwise we will require the company or organization to seek authorization from you again.",
      "cont32": "2.4 Public disclosure",
      "cont33": "We will only publicly disclose your personal information in the following circumstances: ",
      "cont34": "2.4.1 With your express consent; ",
      "cont35" : "2.4.2 Disclosure based on law: We may publicly disclose your personal information in the case of laws, legal procedures, lawsuits or mandatory requirements of government authorities.",
      "cont36" : "3. How do we use cookies, Web beacons, and similar technologies",
      "cont37" : "The current application itself does not use such technology.",
      "cont38" : "4. Permissions and uses that will be requested from you during APP use",
      "cont39" : "The current application invokes some of your device permissions while providing services. Here are the instructions for the current application call permissions and what to ask you before invoking them. ",
      "cont40" : "You can choose to disable some or all of your permissions in the Settings function of the device to refuse the current application from collecting relevant personal information. The mode of displaying and disabling permissions may vary depending on the device. For details, see the device and system developer's instructions or guidelines. ",
      "cont41": "Please note that by enabling any permission, you authorize us to collect and use relevant personal information to provide corresponding services to you; If you close any rights, you cancel the authorization, we will no longer collect and use relevant personal information based on the corresponding rights, and will stop providing corresponding services to you. However, your action to disable permissions will not affect the previous collection and use of information based on your authorization.",
      "cont42": "4.1  have full network access rights for buffering and downloading audio, video, pictures and other resource files required by users to use the service.",
      "cont43": "4.2 check the network connection and its status to determine the network status, to prevent users from misusing a large number of carrier traffic, causing economic losses.",
      "cont44": "4.3 Connecting and disconnecting the WLAN network is used to buffer and download audio, video, pictures and other resource files required by users to use the service.",
      "cont45" : "4.4 Write the permission to the external memory card to save the Vietnamese fluent dynamic pictures downloaded by the user.",
      "cont46" : "4.5 Install application permissions This permission is required for application download and upgrade installation.",
      "cont47" : "5. How do you access and control your personal information",
      "cont48" : "The personal information collected and saved by the current application does not contain your personally identifiable information and has been anonymized and de-identified.",
      "cont49" : "6. How to withdraw authorization",
      "cont50" : "If you no longer wish to grant this app the permission to read and write relevant information, you can withdraw your authorization by selecting \"Settings - Applications - Permission Management\" on your mobile device. Please understand that each business function requires some basic information to be completed. After you withdraw your authorization, we will not be able to continue to provide you with the corresponding services and functions. However, your decision to withdraw your consent or authorization will not affect information previously read based on your authorization.",
      "cont51": "7. Minors use our services",
      "cont52": "We encourage parents or guardians to direct minors under the age of 18 to use our products/services. We recommend that minors encourage their parents or guardians to read this Privacy Policy and that minors seek consent and guidance from their parents or guardians before submitting personal information.",
      "cont53": "8. Complaint and report",
      "cont54": "You can make a complaint or report in accordance with our published system. If you believe that your personal information rights may be infringed, or if you find clues that your personal information rights are infringed (for example, you believe that the personal information we collect violates the law or the agreement between the parties), you can contact us by clicking on the user feedback interface. You can also send complaints to us directly by email to our customer service email address ",
      "cont55" : "9. Scope of application of the Privacy Policy",
      "cont56" : "This Privacy Policy applies to all of our products/services except for certain specific products/services. These specific products/services will be subject to specific privacy policies. Specific privacy policies for certain products/services will be more specific about how we use your information in those products/services. The privacy policy for that particular product/service forms part of this Privacy Policy. If the privacy policy of a particular product/service is inconsistent with this Privacy Policy, the privacy policy of that particular product/service shall apply.",
      "cont57" : "10. Revision of the Privacy Policy",
      "cont58" : "This Privacy Statement will be amended from time to time as necessary. We recommend that users regularly review our privacy policy and Terms of Use online. In the future, if there are any updates to the terms, we will not actively remind the user, will not retrospectively apply the situation that occurred in the past, and will not change the way we treat the information collected before.",
      "cont59" : " . We will check and respond to your complaint and report within 15 working days.",
    },
    cookie:{
      "cont1": 'What are "Cookies"?',
      "cont2": "Cookies are small text files that are transferred to the user's computer or mobile device after you have consented (if required) to visit our website or application. On each subsequent visit, a web browser (such as Internet Explorer, Safari or Google Chrome) sends these cookies back to a website or application in order to identify your personal details or user preferences.",
      "cont3": "Cookies are very useful and can accomplish many different tasks. They help improve the website experience by adapting it to meet your needs. They can identify your preferences and improve the overall user experience.",
      "cont4": "There are two kinds of cookies",
      "cont5" : "Session Cookie. These cookies are only valid for the duration of your online session and will disappear from your computer or device when you close your web browser.",
      "cont6" : "Long-term cookies. After the browser is closed, these cookies remain on your computer or device and last for the time period listed in the cookie. These long-term cookies are activated each time you visit the website for which they were created.",
      "cont7" : "How do we use Cookies?",
      "cont8" : "We use cookies on websites, mobile websites and mobile apps to improve their availability and to collect and store data for marketing and optimization purposes. Cookies are small text files on our website that are saved on your hard drive and subsequently recognized by your web browser. Our cookies do not contain any data about you. Instead, they use pseudonymous user profiles that do not cause any damage to the user's system.",
      "cont9" : "We do not sell the data collected by cookies, nor do we forward this information to any third party, unless required by law (for example, national authorities or legal representatives).",
      "cont10" : "By visiting our website, you consent to our use of the contents described in this cookie Policy. Alternatively, you can opt out by clicking the appropriate button on the cookie banner or by clicking the opt-out link at the bottom of this page.",
      "cont11": "After that, you can revoke this permission at any time by changing your browser's Settings. You can set it up in your browser so that you will be asked for permission every time a cookie tries to access your computer or mobile device, or in some cases disable or completely disable the use of cookies. However, doing so may cause many features on our website to not function properly.",
      "cont12": 'What kind of "cookies" do we use?',
      "cont13": "When you use our website, mobile website or mobile app, the following categories of cookies may be installed on your device:",
      "cont14": "Absolutely required Cookies",
      "cont15" : "These cookies are important because they help navigate our website and use our registration page, among other features. Without these cookies, the website will not function properly. These cookies do not collect any data about you.",
      "cont16" : "Functional Cookies",
      "cont17" : "These cookies allow websites and apps to remember your preferences (such as username or language Settings). They allow us to offer you improved personalization. We cannot use the information collected by these cookies to identify you personally.",
      "cont18" : "Performance Cookies",
      "cont19" : "These cookies help understand how visitors interact with the site by providing information about the area visited, the time spent on the site, and any problems encountered, such as error messages. This helps us improve the performance of our website.",
      "cont20" : "Analytics/advertising cookies",
      "cont30" : "To ensure that websites, mobile websites and mobile apps are easy to use and update, we use Google Analytics, a web analytics service, to understand how visitors use our websites. For example, to enable us to see the most popular content on the site and ensure that the content is constantly updated and improved. The information we collect is anonymous and used for statistical purposes only.",
      "cont31": "Currently, we use the following cookies on our website:",
      "cont32": "Cookie name",
      "cont33": "What kind of data is collected",
      "cont34": "Track when users first visit the site.",
      "cont35" : "Cookies generated by PHP based applications.",
      "cont36" : "None: No user data is stored",
      "cont37" : "Track how many times visitors visit your site.",
      "cont38" : "Calculate how long visitors stay on the site.",
      "cont39" : "Track visitor origin.",
      "cont40" : "Track the number of visitors using the site.",
      "cont41": "Bazaar Voice offers product reviews on its site.",
      "cont42": "Track whether visitors were included in the example that generated the funnel using Hotjar.",
      "cont43": "New Relic- Provides website error reporting.",
      "cont44": "Allows the website to remember the choices made by the user and, in this case, select a cookie policy.",
      "cont45" : "New Relic- Provides website error reporting.",
      "cont46" : "New Relic- Provides website error reporting.",
      "cont47" : "Track users who come to the site from Instagram ads and subsequently visit the URL.",
      "cont48" : "Facebook Pixel sets cookies.",
      "cont49" : "sort",
      "cont50" : "Performance-based cookies",
      "cont51": "Absolutely required Cookie",
      "cont52": "Analytical Cookie",
      "cont53": "Advertising Cookies",
      "cont54": "Service life",
      "cont55" : "2 Years",
      "cont56" : "4 Hours",
      "cont57" : "1 Years",
      "cont58" : "6 Months",
      "cont59" : "24 Hours",
      "cont60" : "10 Months",
      "cont61": "27 Years",
      "cont62": "390 Days",
      "cont63": "90 Days",
    },
    trader:{
      "cont1": "Recommended experts",
      "cont2": "Recent month's yield",
      "cont3": "Total revenue",
      "cont4": "Trading time",
      "cont5" : " day",
      "cont6" : "Ranking of real-time competitive yields",
      "cont7" : "Trading Hours",
      "cont8" : "Yield",
      "cont9" : "Broker",
      "cont10" : "All",
      "cont11": "Today",
      "cont12": "Last week",
      "cont13": "Last month",
      "cont14": "In the past year",
      "cont15" : "Less than",
      "cont16" : "Above",
      "cont17" : "Basic Information",
      "cont18" : "Number of transactions",
      "cont19" : "Trading Lots",
      "cont20" : "Profit and loss amount in the past week",
      "cont21": "No.",
      "cont22": " in total profit margin",
      "cont23": "Trading days",
      "cont24": "Type",
      "cont25" : "Manual",
      "cont26" : "Mix",
      "cont27" : "Signal Description",
      "cont28" : "Profitability",
      "cont29" : "Total profit and loss",
      "cont30" : "Basic Information",
      "cont31": "Username",
      "cont32": "Start time",
      "cont33": "Broker",
      "cont34": "Server",
      "cont35" : "Lever",
      "cont36" : "Account Information",
      "cont37" : "refresh",
      "cont38" : "Current Balance",
      "cont39" : "Current Net Worth",
      "cont40" : "Payment",
      "cont41": "Withdrawal",
      "cont42": "Handling Fees",
      "cont43": "Total transaction volume (lots)",
      "cont44": "lots",
      "cont45" : "Number of transactions",
      "cont46" : "entries",
      "cont47" : "Account Currency",
      "cont48" : "Focus on varieties",
      "cont49" : "Trading Orders",
      "cont50" : "Current Positions",
      "cont51": "Historical orders",
      "cont52": "Transaction Type",
      "cont53": "Please select",
      "cont54": "Opening time",
      "cont55" : "Trading instruments",
      "cont56" : "Lots",
      "cont57" : "Opening price",
      "cont58" : "Stop Loss",
      "cont59" : "Take Profit",
      "cont60" : "Interest",
      "cont61": "Profit",
      "cont62": "Yield Curve",
      "cont63": "Profit and Loss Analysis",
      "cont64": "Variety and unit volume ratio",
      "cont65" : "order number",
      "cont66" : "profit",
      "cont67" : "Total profit and loss",
      "cont68" : "Total number of",
      "cont69" : "Trader Account Management",
      "cont70" : "Binding broker",
      "cont71": "ID",
      "cont72": "Account Number (MT4)",
      "cont73": "Strategy Nickname",
      "cont74": "State",
      "cont75" : "public",
      "cont76" : "hide",
      "cont77" : "operate",
      "cont78" : "Edit",
      "cont79" : "Your grades will not be restored after deleting your account. Are you sure you want to delete it?",
      "cont80" : "Real-time certification",
      "cont81": "Strategy Description",
      "cont82": "Didn't find the server you want? Click to apply to add",
      "cont83": 'The "read-only password" is only used to associate the MT4 account with the website account to obtain order push. FXTOP will keep it strictly confidential, please feel free to use it',
      "cont84": "MT4 Account",
      "cont85" : "Read-only password",
      "cont86" : "Account Status",
      "cont87" : "Public Account",
      "cont88" : "Hide Account",
      "cont89" : "Save imformation",
      "cont90" : "Please enter a strategy nickname",
      "cont91": "Please enter a strategy nickname",
      "cont92": "Please select type",
      "cont93": "Please select a broker",
      "cont94": "Please select a server",
      "cont95" : "Please enter your MT4 account number",
      "cont96" : "Please enter the read-only password",
      "cont97" : "Amount of income",
      "cont98" : "Long",
      "cont99" : "Short",
      "cont100" : "USD",
      "cont101": "Income for the day",
      "cont102": "Profit from closed positions/yield",
      "cont103": "Variety and unit volume ratio",
      "cont104": "Broker Entry Application",
      "cont105" : "Broker Website",
      "cont106" : "Server Name",
      "cont107" : "Sample MT4 Account",
      "cont108" : "Example read-only password",
      "cont109" : "Please enter your broker",
      "cont110" : "Please enter the broker's website",
      "cont111": "Please enter the server",
      "cont112": "Please enter your MT4 account number",
      "cont113": "Please enter the read-only password",
      "cont114": "Condition",
      "cont115" : "",
      "cont116" : "",
      "cont117" : "",
      "cont118" : "",
      "cont119" : "",
      "cont120" : "",
    },
    comment:{
      "cont1": "Comment",
      "cont2": "All Ratings and Reviews",
      "cont3": "Leave Comment",
      "cont4": "Your Comment",
      "cont5" : "Submit Comment",
      "cont6" : "Please input Your Comment",
      "cont7" : "Length should be 15 to 1000",
      "cont8" : "Top Rated",
      "cont9" : "I confirm this review is about my own genuine experience. I am eligible to leave this review, and have not been offered any incentive or payment to leave a review for this company.",
      "cont10" : "",
      "cont11": "",
      "cont12": "",
      "cont13": "",
      "cont14": "",
      "cont15" : "",
      "cont16" : "",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
    },
    broker:{
      "cont1": "Best in ",
      "cont2": "Compare the best companies in this category",
      "cont3": "Sort by",
      "cont4": "Quantity",
      "cont5" : "Highest number of reviews",
      "cont6" : "Most recent reviews",
      "cont7" : "Total",
      "cont8" : "items",
      "cont9" : "Filter By",
      "cont10" : "Rating",
      "cont11": "Categories",
      "cont12": "Recommended Brokers",
      "cont13": "Find your best Broker",
      "cont14": "Find your best Trader",
      "cont15" : "",
      "cont16" : "",
      "cont17" : "",
      "cont18" : "",
      "cont19" : "",
      "cont20" : "",
      "cont21": "",
      "cont22": "",
      "cont23": "",
      "cont24": "",
      "cont25" : "",
      "cont26" : "",
      "cont27" : "",
      "cont28" : "",
      "cont29" : "",
      "cont30" : "",
    },
  };
  